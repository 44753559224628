import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { Button } from "../components/buttons"

export default function ProductPage({ data }) {
  const product = data.productsJson
  return (
    <Layout>
      <SEO title={product.name} />
      <div>
        <div className="flex flex-wrap px-1 lg:px-8">
          <div className="w-full lg:w-2/3 flex m-auto">
            <div className="w-full flex flex-wrap">
              <div className="w-full">
                <div className="bg-blue-700 px-4 py-2 shadow-md text-white rounded-lg mb-8 md:mx-4 md:text-4xl lg:py-2 lg:px-4 leading-normal">
                  <div className="text-2xl my-1 font-semibold">
                    {product.name}
                  </div>
                  {product.description ? (
                    <p className="font-thin text-base my-1">
                      {product.description}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {product.products.map(item => (
                <div className="w-full xl:w-1/2 md:px-4 pb-3 mb-3">
                  <div className="overflow-hidden card">
                    {/*<img
                      className="w-full mb-0"
                      src={
                        "https://placehold.co/600x200/999/666?text=" + item.name
                      }
                      alt="Placeholder"
                    />*/}
                    <div class="p-5">
                      <div className="font-bold mb-2 text-3xl">{item.name}</div>
                      <p
                        className="leading-7 my-2"
                        style={{ color: `var(--textSecondary)` }}
                      >
                        {item.description}
                      </p>
                    </div>
                    <div className="flex flex-wrap px-6 pb-5">
                      {item.variants.map(variant => (
                        <a
                          href={variant.url}
                          target="_blank"
                          className="hover:no-underline"
                          rel="noreferrer"
                        >
                          <Button>
                            {variant.name === "default" || variant.name === ""
                              ? "Download"
                              : variant.name}
                          </Button>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    productsJson(slug: { eq: $slug }) {
      name
      description
      products {
        description
        name
        variants {
          url
          name
          description
        }
      }
    }
  }
`
